<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Tambah Pengumuman'">
          <template v-slot:body>
            <Form
              :form="form"
              :route="'announcements'"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue";
import Form from '@/component/masters/announcements/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {

  data() {
    return {
      form: {
        title: '',
        content: '',
        announcement_category_id: '',
        announcement_category_name: '',
        image: '/images/default-announcement.svg',
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
      },
    };
  },

  components: {
    Card,
    Form
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Pengumuman", route: "/announcements/list" },
      { title: "Tambah Pengumuman" },
    ])
  },

}

</script>